<template>
  <div>
      <div
        class="text-caption mt-n1 mb-1"
        v-if="search.queryDetails"
      >
        <v-icon
          :color="search.sendUpdatesPerEmail.daily
            || search.sendUpdatesPerEmail.realTime
            || search.sendUpdatesPerEmail.weekly ? 'teal lighten-1' : 'grey lighten-1'"
        >
          mdi-email
        </v-icon>
      </div>
      <div
        class="custom-search-title text-body-2 font-weight-medium"
        v-if="search.title"
        style="
          max-height: 48px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        "
      >
        {{ search.title }}
      </div>
      <div
        class="text-body-2 mt-2 text-truncate"
        v-if="search.queryDetails.query"
      >
        {{ search.queryDetails.query }}
      </div>
      <div
        class="text-caption mt-2"
        style="font-size: 80% !important;"
      >
        {{ selectedFiltersShorthand }}
      </div>
  </div>
</template>

<script>
import EuroparlSavedSearchesMixin from '@/mixins/EuroparlSavedSearchesMixin';

export default {
  name: 'europarl-saved-searches-item-detail-saved',

  props: {
    search: {
      type: Object,
      required: true,
    },
  },

  mixins: [EuroparlSavedSearchesMixin],
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1904px) {
  .custom-search-title {
    font-size: 13.6px !important;
  }
}
</style>
